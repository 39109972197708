<template>
  <div id="user-list">
    <!-- app drawer -->
    <user-list-add-new v-model="isAddNewUserSidebarActive" @refetch-data="fetchUsers"></user-list-add-new>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col v-for="total in userTotalLocal" :key="total.total" cols="12" sm="3" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveUserTotalIcon(total.title).color}--text`"
            >
              <v-icon size="25" :color="resolveUserTotalIcon(total.title).color" class="rounded-0">
                {{ resolveUserTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title> Usuarios </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <v-col cols="12" sm="4">
          <v-select
            v-model="roleFilter"
            placeholder="Rol"
            :items="$store.getters.roleOptions.filter(r => $can(r.value, 'roles'))"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-btn color="error" class="mb-4 me-3" v-show="selectedRows.length" @click="deleteUsersMany">
          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
          <span>Eliminar Seleccionados</span>
        </v-btn>
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Buscar"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Agregar Usuario</span>
          </v-btn>

          <v-btn color="secondary" class="mb-4 me-3" @click="dialogs.importList = true">
            <v-icon size="17" class="me-1">
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
            <span>Importar</span>
          </v-btn>

          <v-btn color="secondary" outlined @click="exportUsers" class="mb-4">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>

          <ImportListTool
            v-model="dialogs.importList"
            :columns="importColumns"
            :fileSize="50000000"
            @result="uploadBatch"
          />
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        item-key="_id"
        :headers="tableColumns"
        :items="userListTable"
        :options.sync="options"
        :footer-props="{
          'items-per-page-text': 'Usuarios por página: ',
          'items-per-page-options': [15, 30, 50, 100],
        }"
        loading-text="Cargando usuarios..."
        :server-items-length="totalUserListTable"
        :loading="loading"
        show-select
      >
        <!-- name -->
        <template #[`item.avatar`]="{ item }">
          <div class="d-flex align-center">
            <router-link
              :to="{ name: 'apps-user-view', params: { id: item._id } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              <v-avatar
                :color="item.avatar ? '' : 'primary'"
                :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="32"
              >
                <v-img
                  v-if="item.avatar"
                  :src="$store.state.apidomain + '/user/avatars/' + item.avatar + '?rand=' + rand"
                ></v-img>
                <span v-else class="font-weight-medium">{{ avatarText(item.name + ' ' + item.lastname) }}</span>
              </v-avatar>
            </router-link>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.role)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.role)}--text me-3`"
            >
              <v-icon size="18" :color="resolveUserRoleVariant(item.role)">
                {{ resolveUserRoleIcon(item.role) }}
              </v-icon>
            </v-avatar>
            <span>{{ resolveUserRoleTitle(item.role) }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-user-view', params: { id: item._id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Detalle</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="deleteItem(item._id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Eliminar</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
        </template>
        <template v-slot:no-data> No hay usuarios para mostrar </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiCloudUploadOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from 'vue'

// sidebar
import { avatarText } from '@core/utils/filter'
import UserListAddNew from './UserListAddNew.vue'

import useUsersList from './useUsersList'

import ImportListTool from '@/views/components/ImportList/ImportListTool.vue'

export default {
  components: {
    UserListAddNew,
    ImportListTool,
  },
  setup() {
    const dialogs = ref({
      importList: false,
    })

    const rand = ref(Date.now())

    const deleteItem = async item => {
      const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
        title: '¿Esta seguro que desea borrarlo?',
        message: 'No podra recuperarlo',
      })
      if (didConfirm) {
        deleteUser(item)
      }
    }

    const deleteUsersMany = async () => {
      const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
        title: '¿Esta seguro que desea borrar los usuarios seleccionados?',
        message: 'No podrá recuperarlos',
      })
      if (didConfirm) {
        deleteMany()
      }
    }

    const {
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      selectedRows,
      exportUsers,
      deleteMany,
      fetchUsers,
      deleteUser,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserRoleTitle,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      importColumns,
      uploadBatch,
    } = useUsersList()

    const isAddNewUserSidebarActive = ref(false)

    return {
      rand,
      exportUsers,
      deleteItem,
      deleteUsersMany,
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      isAddNewUserSidebarActive,
      selectedRows,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserRoleTitle,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      fetchUsers,
      importColumns,
      dialogs,
      uploadBatch,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
