<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Nuevo Usuario</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="userData.document"
            :rules="[validators.required]"
            outlined
            dense
            autocomplete="off"
            label="Documento"
            placeholder="49434457"
            hide-details="auto"
            class="mb-6"
            @change="searchDocument"
            :loading="loading.document"
          ></v-text-field>

          <v-text-field
            v-model="userData.name"
            outlined
            dense
            :rules="[validators.required]"
            label="Nombre"
            placeholder="Rodrigo"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.lastname"
            :rules="[validators.required]"
            outlined
            dense
            label="Apellido"
            placeholder="Perez"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.password"
            :rules="userData._id ? [] : [validators.required]"
            outlined
            dense
            label="Contraseña"
            hide-details="auto"
            class="mb-6"
            autocomplete="off"
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            @click:append="isPasswordVisible = !isPasswordVisible"
          ></v-text-field>

          <v-text-field
            v-model="userData.email"
            outlined
            dense
            label="Email"
            placeholder="email@email.com"
            autocomplete="off"
            :rules="[validators.emailValidator]"
            type="email"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.phone"
            outlined
            dense
            label="Teléfono"
            placeholder="099659684"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.address"
            outlined
            dense
            label="Dirección"
            placeholder="18 de Julio 1000"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.city"
            outlined
            dense
            label="Ciudad"
            placeholder="Montevideo"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <datepicker-birthday-picker class="mb-6" v-model="userData.birthday"></datepicker-birthday-picker>

          <v-select
            v-model="userData.role"
            :rules="[validators.requiredSelect]"
            label="Rol"
            :items="$store.state.roleOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <companies-selection v-model="userData.company" class="mb-6"></companies-selection>

          <v-btn color="primary" class="me-3" type="submit" :loading="loading.submit">
            {{ userData._id ? 'Actualizar' : 'Agregar' }}
          </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetuserData"> Cancelar </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import snackbarPlugin from '@/plugins/snackbar'
import store from '@/store'
import { emailValidator, required, requiredSelect } from '@core/utils/validation'
import { mdiClose, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from 'vue'
import datepickerBirthdayPicker from '../user-view/user-bio-panel/DatepickerBirthdayPicker.vue'
import CompaniesSelection from '@/views/components/CompaniesSelection.vue'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    datepickerBirthdayPicker,
    CompaniesSelection,
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      lastname: '',
      document: '',
      password: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      role: null,
    }

    const valid = ref(false)
    const form = ref(null)
    const isPasswordVisible = ref(false)
    const loading = ref({
      document: false,
      submit: false,
    })

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
      loading.value.submit = false
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value.submit = true
        if (userData.value._id) {
          store
            .dispatch('user/updateUser', userData.value)
            .then(() => {
              emit('update:is-add-new-user-sidebar-active', false)
              emit('refetch-data')
              snackbarPlugin.showMessage({ content: 'Agregado Correctamente', color: 'success', timeout: 10000 })
              resetuserData()
            })
            .catch(e => {
              snackbarPlugin.showMessage({
                content: e.response.data.message,
                color: 'error',
                timeout: 10000,
              })
              loading.value.submit = false
            })
        } else {
          store
            .dispatch('user/addUser', userData.value)
            .then(() => {
              emit('update:is-add-new-user-sidebar-active', false)
              emit('refetch-data')
              snackbarPlugin.showMessage({ content: 'Agregado Correctamente', color: 'success', timeout: 10000 })
              resetuserData()
            })
            .catch(e => {
              snackbarPlugin.showMessage({
                content: e.response.data.message,
                color: 'error',
                timeout: 10000,
              })
              loading.value.submit = false
            })
        }
      } else {
        validate()
      }
    }

    const searchDocument = () => {
      loading.value.document = true
      store
        .dispatch('user/fetchUsers', {
          document: userData.value.document,
        })
        .then(response => {
          if (response.data.filteredData.length) userData.value = response.data.filteredData[0]
          loading.value.document = false
        })
        .catch(error => {
          console.log(error)
        })
    }

    return {
      loading,
      searchDocument,
      resetuserData,
      form,
      onSubmit,
      userData,
      valid,
      validate,
      isPasswordVisible,

      // validation
      validators: { required, emailValidator, requiredSelect },
      icons: {
        mdiClose,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>
